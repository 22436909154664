import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import ControlerTwoRange from "../components/ControlerTwoRange/ControlerTwoRange";
import { useHttp } from "../hooks/http.hook";
import { setSettings, set404 } from "../store/actions";
import { API_PATH } from "../api/index";
import { debounce } from "../utils/utils";
import { saveSettings, servoSave } from "../api";
import {ReactComponent as BackIco} from "../img/icons/menu-day/back.svg";
//robot
import {ReactComponent as RobotFaceIco} from "../img/robot/robot-face.svg";
import {ReactComponent as RobotNeckIco} from "../img/robot/robot-neck.svg";
import {ReactComponent as RobotArmIco} from "../img/robot/robot-arm.svg";
import {ReactComponent as RobotHand} from "../img/robot/robot-hand.svg";
import {ReactComponent as RobotLogoIco} from "../img/robot/robot-logo.svg";
import {ReactComponent as RobotBodyIco} from "../img/robot/robot-body.svg";
import {ReactComponent as RobotLeftShoulderIco} from "../img/robot/robot-left-shoulder.svg";
import {ReactComponent as RobotRightShoulderIco} from "../img/robot/robot-right-shoulder.svg";

import './SafeZones.scss';

const safeZone = {min: 0, max: 1000};
const saveSettingsDebounce = debounce(saveSettings, 500);
const saveServoDebounce = debounce(servoSave, 500);

const SafeZones = () => {
  const isDay = useSelector((state) => state.isDay);
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const { request, loading, error } = useHttp();
  const navigate = useNavigate();
    const goBack = () => {
    navigate(-1);
  };
  const [l1_maxDeg, setL1_max] = useState();
  const [l1_minDeg, setL1_min] = useState();
  const [l2_maxDeg, setL2_max] = useState();
  const [l2_minDeg, setL2_min] = useState();
  const [l3_maxDeg, setL3_max] = useState();
  const [l3_minDeg, setL3_min] = useState();
  const [l4_maxDeg, setL4_max] = useState();
  const [l4_minDeg, setL4_min] = useState();
  const [l5_maxDeg, setL5_max] = useState();
  const [l5_minDeg, setL5_min] = useState();
  const [r1_maxDeg, setR1_max] = useState();
  const [r1_minDeg, setR1_min] = useState();
  const [r2_maxDeg, setR2_max] = useState();
  const [r2_minDeg, setR2_min] = useState();
  const [r3_maxDeg, setR3_max] = useState();
  const [r3_minDeg, setR3_min] = useState();
  const [r4_maxDeg, setR4_max] = useState();
  const [r4_minDeg, setR4_min] = useState();
  const [r5_maxDeg, setR5_max] = useState();
  const [r5_minDeg, setR5_min] = useState();
  const [neck_maxDeg, setNeck_max] = useState();
  const [neck_minDeg, setNeck_min] = useState();
  const [head_maxDeg, setHead_max] = useState();
  const [head_minDeg, setHead_min] = useState();
  const [activeRobotPart, setActiveRobotPart] = useState(null);
  const [activeRobotPartName, setActiveRobotPartName] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isNeck, setIsNeck] = useState(true);
  const [servo, setServo] = useState({
    "l1": 0,
    "l2": 0,
    "l3": 0,
    "l4": 0,
    "l5": 0,
    "neck": 0,
    "head": 0,
    "r1": 0,
    "r2": 0,
    "r3": 0,
    "r4": 0,
    "r5": 0,
    "detach": true
  });

  const handleRobotPartChoice = (robotPart) => {

    if (activeRobotPart === robotPart) {
      setActiveRobotPart(null);
      setActiveRobotPartName(null);
    } else {
      setActiveRobotPart(robotPart);
      switch (robotPart) {
        case "neck":
          setActiveRobotPartName("Шея");
          break;
        case "l1":
          setActiveRobotPartName("Левая рука 1");
          break;
        case "l2":
          setActiveRobotPartName("Левая рука 2");
          break;
        case "l3":
          setActiveRobotPartName("Левая рука 3");
          break;
        case "l4":
          setActiveRobotPartName("Левая рука 4");
          break;
        case "l5":
          setActiveRobotPartName("Левая рука 5");
          break;
        case "r1":
          setActiveRobotPartName("Правая рука 1");
          break;
        case "r2":
          setActiveRobotPartName("Правая рука 2");
          break;
        case "r3":
          setActiveRobotPartName("Правая рука 3");
          break;
        case "r4":
          setActiveRobotPartName("Правая рука 4");
          break;
        case "r5":
          setActiveRobotPartName("Правая рука 5");
          break;
        default:
          setActiveRobotPartName(null);
          break;
      }
    }
    setIsReady(false);
  };
  const changeControlState = (robotPart, minValue, maxValue) => {
    switch (robotPart) {
      case "neck":
        if (isNeck) {
          if (maxValue !== neck_maxDeg){
            setServo({
              ...servo,
              [robotPart]: maxValue,
            })
          }
          if (minValue !== neck_minDeg) {
            setServo({
              ...servo,
              [robotPart]: minValue,
            })
          }
          setNeck_max(maxValue);
          setNeck_min(minValue);
          saveSettingsDebounce({neck_max: neck_maxDeg, neck_min: neck_minDeg,});
        } else {
          if (maxValue !== head_maxDeg){
            setServo({
              ...servo,
              "head": maxValue,
              "neck": 0,
            })
          }
          if (minValue !== head_minDeg) {
            setServo({
              ...servo,
              "head": minValue,
              "neck": 0,
            })
          }
          setHead_max(maxValue);
          setHead_min(minValue);
          saveSettingsDebounce({head_max: head_maxDeg, head_min: head_minDeg,});
        }
        break;
      case "l1":
        if (maxValue !== l1_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== l1_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setL1_max(maxValue);
        setL1_min(minValue);
        saveSettingsDebounce({l1_max: l1_maxDeg, l1_min: l1_minDeg,});
        break;
      case "l2":
        if (maxValue !== l2_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== l2_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setL2_max(maxValue);
        setL2_min(minValue);
        saveSettingsDebounce({l2_max: l2_maxDeg, l2_min: l2_minDeg,});
        break;
      case "l3":
        if (maxValue !== l3_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== l3_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setL3_max(maxValue);
        setL3_min(minValue);
        saveSettingsDebounce({l3_max: l3_maxDeg, l3_min: l3_minDeg,});
        break;
      case "l4":
        if (maxValue !== l4_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== l4_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setL4_max(maxValue);
        setL4_min(minValue);
        saveSettingsDebounce({l4_max: l4_maxDeg, l4_min: l4_minDeg,});
        break;
      case "l5":
        if (maxValue !== l5_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== l5_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setL5_max(maxValue);
        setL5_min(minValue);
        saveSettingsDebounce({l5_max: l5_maxDeg, l5_min: l5_minDeg,});
        break;
      case "r1":
        if (maxValue !== r1_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== r1_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setR1_max(maxValue);
        setR1_min(minValue);
        saveSettingsDebounce({r1_max: r1_maxDeg, r1_min: r1_minDeg,});
        break;
      case "r2":
        if (maxValue !== r2_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== r2_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setR2_max(maxValue);
        setR2_min(minValue);
        saveSettingsDebounce({r2_max: r2_maxDeg, r2_min: r2_minDeg,});
        break;
      case "r3":
        if (maxValue !== r3_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== r3_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setR3_max(maxValue);
        setR3_min(minValue);
        saveSettingsDebounce({r3_max: r3_maxDeg, r3_min: r3_minDeg,});
        break;
      case "r4":
        if (maxValue !== r4_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== r4_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setR4_max(maxValue);
        setR4_min(minValue);
        saveSettingsDebounce({r4_max: r4_maxDeg, r4_min: r4_minDeg,});
        break;
      case "r5":
        if (maxValue !== r5_maxDeg){
          setServo({
            ...servo,
            [robotPart]: maxValue,
          })
        }
        if (minValue !== r5_minDeg) {
          setServo({
            ...servo,
            [robotPart]: minValue,
          })
        }
        setR5_max(maxValue);
        setR5_min(minValue);
        saveSettingsDebounce({r5_max: r5_maxDeg, r5_min: r5_minDeg,});
        break;
      default:
        break;
    }
    saveServoDebounce(servo);
  };

  const handleClick = () => {
    setIsReady(true);
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/endstops/1/`);
      const data = await response;
       dispatch(setSettings(data));
      };
    fetchData();
  };
  useEffect(() => {
    const fetchData = async () => {
    const response = await request(`${API_PATH}api/endstops/1/`);
    const data = await response;
     dispatch(setSettings(data));
    };
    fetchData();
  }, []);

  if (loading) {
    return <h1 className="loading-text" style={{ textAlign: "center" }}>Идёт загрузка...</h1>;
  }
  if (error) {
    dispatch(set404(true));
  }
  return (
    <div className="safezones__wrapper">
      <div
        className={classnames("safezones", {
          safezones_day: isDay,
          safezones_night: !isDay,
        })}
      >
        <div className="safezones__content">
          <div
            className={classnames("safezones__header", {
              safezones__header_day: isDay,
              safezones__header_night: !isDay,
            })}
          >
            <button
              onClick={goBack}
              className={classnames("safezones__back-btn", {
                "safezones__back-btn_day": isDay,
                "safezones__back-btn_night": !isDay,
              })}
            >
              <BackIco />
              Безопасные зоны
            </button>
          </div>
          <div className="safezones__container">
            <p className="safezones__description">
              Настройка безопасных зон необходима, чтобы робот в процессе эксплуатации не столкнулся конечностью с внешним припятствием
            </p>
            <div>
              <div className="safezones__body-item">
                <div
                  className={classnames("safezones__robot", {
                    safezones__robot_day: isDay,
                    safezones__robot_night: !isDay,
                  })}
                >
                  <div className="safezones__head">
                    <span
                      className={classnames("safezones__face", {
                        safezones__face_day: isDay,
                        safezones__face_night: !isDay,
                      })}
                      onClick={() => handleRobotPartChoice("neck")}
                    >
                      <RobotFaceIco />
                    </span>
                    <span
                      className={classnames("safezones__neck", {
                        safezones__neck_day: isDay,
                        safezones__neck_night: !isDay,
                        active_robot_part_day: activeRobotPart === "neck" & isDay,
                        active_robot_part_night: activeRobotPart === "neck" & !isDay,
                      })}
                      onClick={() => handleRobotPartChoice("neck")}
                    >
                      <RobotNeckIco />
                    </span>
                  </div>
                  <div className="safezones__body">
                    <div className="safezones__arm">
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "l4" & isDay,
                          active_robot_part_night: activeRobotPart === "l4" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("l4")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "l3" & isDay,
                          active_robot_part_night: activeRobotPart === "l3" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("l3")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "l2" & isDay,
                          active_robot_part_night: activeRobotPart === "l2" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("l2")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__hand-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "l1" & isDay,
                          active_robot_part_night: activeRobotPart === "l1" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("l1")}
                      >
                        <RobotHand />
                      </span>
                    </div>
                    <div className="safezones__torso">
                      <span
                        className={classnames("safezones__logo", {
                          safezones__logo_day: isDay,
                          safezones__logo_night: !isDay,
                        })}
                      >
                        <RobotLogoIco />
                      </span>
                      <span
                        className={classnames("safezones__breast", {
                          safezones__breast_day: isDay,
                          safezones__breast_night: !isDay,
                        })}
                      >
                        <RobotBodyIco />
                      </span>
                      <span
                        className={classnames("safezones__left_shoulder", {
                          safezones__left_shoulder_day: isDay,
                          safezones__left_shoulder_night: !isDay,
                          active_robot_part_day: activeRobotPart === "l5" & isDay,
                          active_robot_part_night: activeRobotPart === "l5" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("l5")}
                      >
                        <RobotLeftShoulderIco />
                      </span>
                      <span
                        className={classnames("safezones__right_shoulder", {
                          safezones__right_shoulder_day: isDay,
                          safezones__right_shoulder_night: !isDay,
                          active_robot_part_day: activeRobotPart === "r5" & isDay,
                          active_robot_part_night: activeRobotPart === "r5" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("r5")}
                      >
                        <RobotRightShoulderIco />
                      </span>
                    </div>
                    <div className="safezones__arm">
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "r4" & isDay,
                          active_robot_part_night: activeRobotPart === "r4" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("r4")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "r3" & isDay,
                          active_robot_part_night: activeRobotPart === "r3" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("r3")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__arm-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "r2" & isDay,
                          active_robot_part_night: activeRobotPart === "r2" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("r2")}
                      >
                        <RobotArmIco />
                      </span>
                      <span
                        className={classnames("safezones__hand-item", {
                          safezones__armitem_day: isDay,
                          safezones__armitem_night: !isDay,
                          active_robot_part_day: activeRobotPart === "r1" & isDay,
                          active_robot_part_night: activeRobotPart === "r1" & !isDay,
                        })}
                        onClick={() => handleRobotPartChoice("r1")}
                      >
                        <RobotHand />
                      </span>
                    </div>
                  </div>
                </div>
                {isReady ? (
                  <div
                    className={classnames("safezones__control", {
                      "safezones__control_day": isDay,
                      "safezones__control_night": !isDay,
                    })}
                  >
                    <h2 className="safezones__control-name">
                      Конечность настроена
                    </h2>
                    <p className="safezones__control-description">При необходимости выберите следующую конечность для настройки</p>
                  </div>
                ) : (
                  <div
                    className={classnames("safezones__control", {
                      "safezones__control_day": isDay,
                      "safezones__control_night": !isDay,
                    })}
                  >
                    {activeRobotPartName ? (
                      <>
                        <div className="safezones__control-header">
                          { activeRobotPart === "neck" & isNeck ?
                            <BackIco
                              onClick={() => setIsNeck(false)}
                            />
                           : null}
                           { activeRobotPart === "neck" & !isNeck ?
                            <BackIco
                              className="safezones__btn-forward"
                              onClick={() => setIsNeck(true)}
                            />
                            : null}
                          <h2 className="safezones__control-path-name">
                            {activeRobotPartName}
                          </h2>
                          { activeRobotPart === "neck" ?
                            <div className="safezones__control-path-number">
                              <span>{isNeck ? 1 : 2}</span>
                              <span>/</span>
                              <span>2</span>
                            </div>
                            : null
                          }
                        </div>
                        <p className="safezones__control-description">
                          Установите максимально допустимые значения для {isNeck ? "левой стороны, а затем для правой стороны": "движения вверх, а затем для движения вниз"}
                        </p>
                        <div className="safezones__controller">
                          {activeRobotPart === "r1" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.r1_max}
                              minInitialValue={settings.r1_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("r1", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "r2" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.r2_max}
                              minInitialValue={settings.r2_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("r2", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "r3" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.r3_max}
                              minInitialValue={settings.r3_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("r3", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "r4" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.r4_max}
                              minInitialValue={settings.r4_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("r4", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "r5" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.r5_max}
                              minInitialValue={settings.r5_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("r5", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "l1" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.l1_max}
                              minInitialValue={settings.l1_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("l1", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "l2" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.l2_max}
                              minInitialValue={settings.l2_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("l2", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "l3" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.l3_max}
                              minInitialValue={settings.l3_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("l3", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "l4" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.l4_max}
                              minInitialValue={settings.l4_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("l4", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "l5" ? (
                            <ControlerTwoRange
                              maxValue={safeZone.max}
                              minValue={safeZone.min}
                              maxInitialValue={settings.l5_max}
                              minInitialValue={settings.l5_min}
                              id={"horizontal"}
                              onChange={({maxValue, minValue}) => changeControlState("l5", minValue, maxValue)}
                            ></ControlerTwoRange>
                          ) : null}
                          {activeRobotPart === "neck" ? (
                            <>
                              {isNeck ?
                                <ControlerTwoRange
                                  maxValue={safeZone.max}
                                  minValue={safeZone.min}
                                  maxInitialValue={settings.neck_max}
                                  minInitialValue={settings.neck_min}
                                  id={"horizontal"}
                                  onChange={({maxValue, minValue}) => changeControlState("neck", minValue, maxValue)}
                                ></ControlerTwoRange>
                                : null }
                              {isNeck ? null :
                                <ControlerTwoRange
                                  maxValue={safeZone.max}
                                  minValue={safeZone.min}
                                  maxInitialValue={settings.head_max}
                                  minInitialValue={settings.head_min}
                                  id={"vertical"}
                                  onChange={({maxValue, minValue}) => changeControlState("neck", minValue, maxValue)}
                                ></ControlerTwoRange>
                              }
                            </>
                          ) : null}
                        </div>
                        <button
                          className={classnames("safezones__ready-btn", {
                            "safezones__ready-btn_day": isDay,
                            "safezones__ready-btn_night": !isDay,
                          })}
                          onClick={handleClick}
                        >Готово</button>
                      </>
                     ) : (
                      <h2 className="safezones__control-name">
                        Выберите конечность робота для настройки
                      </h2>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeZones;