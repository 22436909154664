import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useHttp } from "../../hooks/http.hook";
import { /*toggleIsRecognitionStartModalOpen,*/ setRecognitions } from "../../store/actions";
import { API_PATH, saveRecognitionName, triggersGet } from "../../api/index";

import {ReactComponent as CloseItemIco} from '../../img/close.svg';
//import { ReactComponent as BackIco } from "../../img/icons/menu-day/back.svg";

import "./ModalRecognitionName.scss";

const ModalRecognitionName = ({ isOpen, onClose, type}) => {
  const isDay = useSelector((state) => state.isDay);
  const { request, loading } = useHttp();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(type == 2 ? 'Введите название жеста' : 'Введите имя');
  const inputRef = useRef(null);
  const [helperText, setHelperText] = useState("");

  const handleModalClose = () => {
    onClose();
  };

  /*const goBack = () => {
    onClose();
    dispatch(toggleIsAddRecognitionModalOpen());
  };*/

  const handleClick = async () => {
    onClose();
    await saveRecognitionName(inputValue, type);
    setInputValue(type == 2 ? 'Введите название жеста' : 'Введите имя');
    inputRef.current.value="";
    const data = await triggersGet();
    const res = data.filter((item) => item.trigger_type == 1 || item.trigger_type == 2);
    dispatch(setRecognitions(res));
  }
  const onRecognitionNameInput = async () => {
    const resInput = await request(`${API_PATH}api/is_script_unique/`, "post",
    JSON.stringify({
      name: inputRef.current.value,
    }));
    (resInput.unique == false) ? setHelperText("Такое имя уже существует") : setHelperText("");
    setInputValue(inputRef.current.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    setInputValue(inputValue)
  }, [inputValue]);

  return (
    <div
      className={classNames("modal", {
        "modal-recognition-name--open": isOpen,
      })}
    >
      <div
        className={classNames("modal-recognition-name__inner", {
          "modal-recognition-name__inner--day": isDay,
          "modal-recognition-name__inner--night": !isDay,
        })}
      >
        <div className="modal-recognition-name__header">
          <div className="modal-recognition-name__header-top">
            <div className="modal-recognition-name__top-container">
              {/*<button
                className={classNames("modal-recognition-name-start__back", {
                  "modal-recognition-name-start__back--day": isDay,
                  "modal-recognition-name-start__back--night": !isDay,
                })}
                onClick={goBack}
              >
                <BackIco />
              </button>*/}
              <h2 className="modal-recognition-name__title">
                {(!!type & type == 2) ? "Как назовём этот жест?" : "Как зовут человека?"}
              </h2>
            </div>
            <button onClick={handleModalClose} className="modal-recognition-name__close">
              <CloseItemIco />
            </button>
          </div>
        </div>
        <div className="modal-recognition-name__body">
              <form
                onSubmit={(e) => onSubmit(e)} // сабмит происходит при нажатии на enter
                className="modal-recognition-name__form"
              >
                <div className="modal-recognition-name__form-container">
                  <input
                    className={classNames("modal-recognition-name__input", {
                      "modal-recognition-name__input_day": isDay,
                      "modal-recognition-name__input_night": !isDay,
                    })}
                    ref={inputRef} // Привязка рефа к инпуту
                    type="text"
                    placeholder={type == 2 ? 'Введите название жеста' : 'Введите имя'}
                    name="inputName"
                    id="inputName"
                    onInput={onRecognitionNameInput}
                  />
                  <label
                    htmlFor="inputName"
                  >
                  </label>
                </div>
                <div className="modal-recognition-name__name-helper-text">{helperText}</div>
              </form>
          <button
            className={classNames("modal-recognition-name-btn", {
              "modal-recognition-name-btn_day": isDay,
              "modal-recognition-name-btn_night": !isDay,
            })}
            onClick={handleClick}
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalRecognitionName;
