export const API_PATH = 'http://localhost:8000/';

export const triggersGet = async() =>{
  const response = await fetch(`${API_PATH}api/trigger/`);
  return  await response.json();
};
//создание мимики на сервер
export const mimicCreate = async (mimicName, items) => {
  const res = await fetch("http://localhost:8000/api/save_mimic_items/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body:  JSON.stringify({
      name: mimicName,
      mimic_items: items,
    })
  });
  return  await res.json();
};
//редактрирование мимики
export const mimicUpdate = async (mimicId, mimicName, items) => {
  const res = await fetch("http://localhost:8000/api/save_mimic_items/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body:  JSON.stringify({
      id: mimicId,
      name: mimicName,
      mimic_items: items,
    })
  });
  return  await res.json();
};
//изменение ползунков в мимике в реальном времени
export const mimicTest = async (data) => {
  const res = await fetch("http://localhost:8000/api/test_mimic/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body:  JSON.stringify(data)
  });
  return  await res.json();
};

//сохранение движения на сервер
export const moveSave = async (moveId, moveName, poses) => {
  const res = await fetch("http://localhost:8000/api/save_poses/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({
      id: moveId,
      name: moveName,
      poses: poses,
    })
  });
  return  await res.json();
};
// сохранение сервоприводов на сервер (на главной странице)
export const servoSave = async (data) => {
  const res = await fetch("http://localhost:8000/api/run_servo/", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  return  await res.json();
};

//получить все сервы
export const getServo = async() =>{
  const res = await fetch(`${API_PATH}api/current_position/`);
  return  await res.json();
}

export const getVolume = async() => {
  const response = await fetch(`${API_PATH}api/volume/`);
  return  await response.json();
}


export const getSettings = async() =>{
  const res = await fetch(`${API_PATH}api/endstops/1/`);
  return  await res.json();
}

export const saveSettings = async(data) =>{
  const res = await fetch(`${API_PATH}api/endstops/1/`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  return  await res.json();
}

export const saveRobotName = async(data) =>{
  const res = await fetch(`${API_PATH}api/robot_name/`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({
      name: data,
    })
  });
  return  await res.json();
}
//сохранение настроек
export const saveSettings2 = async(data) =>{
  const res = await fetch(`${API_PATH}api/settings/1/`, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  return  await res.json();
}

//получить настройки
export const getSettings2 = async() =>{
  const res = await fetch(`${API_PATH}api/settings/1/`);
  return  await res.json();
}

//робот говорит фразу
export const robotSayPost = async(data) =>{
  const res = await fetch(`${API_PATH}api/say/`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  return  await res.json();
}

export const saveRecognitionName = async (name, type) => {
  const res = await fetch(`${API_PATH}api/save_new_detection/`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body:  JSON.stringify({
      name: name,
      trigger_type: type,
    })
  });
  return  await res.json();
};

//play move
export const runMovePost = async (id) => {
  const res = await fetch(`http://localhost:8000/api/run_move/${id}/`, {method:"POST"});
  return  await res.json();
}

//play mimic
export const runMimicPost = async (id) => {
  const res = await fetch(`http://localhost:8000/api/run_mimic/${id}/`, {method:"POST"});
  return  await res.json();
}

//run update
export const getUpdate = async() =>{
  const response = await fetch(`${API_PATH}api/update/`);
  return  await response.json();
};


