import { useSelector } from "react-redux";
import "./InputBottom.scss";
import InputDay from "../../img/input-day/send-day.svg";
import InputNight from "../../img/input-night/send.svg";
import { robotSayPost } from "../../api/index";
import { useState } from "react";

const InputBottom = () => {
  const isDay = useSelector((state) => state.isDay);
  const [phrase, setPhrase] = useState("");

  const  onPhraseClick = () => {
    robotSayPost({
      "phrase": phrase,
      "end": true,
    });
  }

  if (isDay) {
    return (
      <form className="robot__form" action="#">
        <input
          placeholder="Введите текст для робота"
          type="text"
          className="robot__input-day"
          value={phrase}
          onInput={(e) => setPhrase(e.target.value)}
        />
        <button
          className="robot__send"
          onClick={onPhraseClick}
        >
          <img src={InputDay} alt="Отправить" />
        </button>
      </form>
    );
  } else {
    return (
      <form className="robot__form" action="#">
        <input
          placeholder="Введите текст для робота"
          type="text"
          className="robot__input-night"
          value={phrase}
          onInput={(e) => setPhrase(e.target.value)}
        />
        <button
          className="robot__send"
          onClick={onPhraseClick}
        >
          <img src={InputNight} alt="Отправить" />
        </button>
      </form>
    );
  }
};

export default InputBottom;
