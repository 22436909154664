import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import TopBottom from "../../img/robot-control-day/top-bottom.svg";
import RightLeft from "../../img/robot-control-day/right-left.svg";
import TopBottomNight from "../../img/robot-control-night/top-bottom.svg";
import RightLeftNight from "../../img/robot-control-night/right-left.svg";

import "./RobotControl.scss";

import Controler from "../Controler/Controler";
import { useState } from "react";

const RobotControl = ({bottom, onChange, servo}) => {
  const isDay = useSelector((state) => state.isDay);
  const activeRobotPart = useSelector((state) => state.activeRobotPart);
  const settings = useSelector((state) => state.settings);
  const itemClass = `robot__modal-control ${
    activeRobotPart ? `robot__modal-control--active` : ""
  }`;

  const isTablet = useMediaQuery({
    query: "(max-width: 850px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 650px)",
  });
  const bottomStyle = !isTablet & !isMobile & !(activeRobotPart === "neck") ? bottom : null;
  const horizontalInput =
    activeRobotPart === "neck" && isDay ? (
      <>
        {/* {"Голова-шея"} */}
        <Controler imgSrc={RightLeft} value={servo.head} id={"vertical"} onChange={(value) => onChange(value, "head")} maxValue={settings.head_max} minValue={settings.head_min}/>
        <Controler imgSrc={TopBottom} value={servo.neck} id={"horizontal"} onChange={(value) => onChange(value, "neck")} maxValue={settings.neck_max} minValue={settings.neck_min} />
      </>
    ) : activeRobotPart === "neck" && !isDay ? (
      <>
        {/* {"Голова-шея ночь"} */}
        <Controler imgSrc={RightLeftNight} value={servo.head} id={"vertical"} onChange={(value) => onChange(value, "head")} maxValue={settings.head_max} minValue={settings.head_min} />
        <Controler
          imgSrc={TopBottomNight}
          value={servo.neck}
          id={"horizontal"}
          onChange={(value) => onChange(value, "neck")}
          maxValue={settings.neck_max}
          minValue={settings.neck_min}
        />
      </>
    ) : null;

  if (isDay) {
    return (
      <div className={itemClass} style={{bottom: bottomStyle}}>
        {horizontalInput}
        {activeRobotPart === "neck" ? null : (
          <>
            {/* на все остальные конечности */}
            {/* {servo[activeRobotPart]} */}
            <Controler imgSrc={RightLeft} value={servo[activeRobotPart]} id={"vertical"} onChange={(value) => onChange(value, activeRobotPart)} maxValue={settings[`${activeRobotPart}_max`]} minValue={settings[`${activeRobotPart}_min`]} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className={`${itemClass} robot__modal-control--night`} style={{bottom: bottomStyle}}>
        {horizontalInput}
        {activeRobotPart === "neck" ? null : (
          <>
            {/* на все остальные конечности - ночь */}
            <Controler
              imgSrc={RightLeftNight}
              value={servo[activeRobotPart]}
              id={"vertical"}
              onChange={(value) => onChange(value, activeRobotPart)}
              maxValue={150}
            />
          </>
        )}
      </div>
    );
  }
};

export default RobotControl;
