import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
//import { useHttp } from "../../hooks/http.hook";
import { toggleIsAddRecognitionModalOpen } from "../../store/actions";
import { saveSettings2 } from "../../api";

import Portal from '../Portal';
import ModalRecognitionName from '../ModalRecognitionName/ModalRecognitionName';

import {ReactComponent as CloseItemIco} from '../../img/close.svg';
import { ReactComponent as BackIco } from "../../img/icons/menu-day/back.svg";

import "./ModalRecognitionStart.scss";

const ModalRecognitionStart = ({ isOpen, onClose, type, modalType}) => {
  const isDay = useSelector((state) => state.isDay);
  //const { request, loading } = useHttp();
  const dispatch = useDispatch();
  const [isModalRecognitionNameOpen, setIsModalRecognitionNameOpen] = useState(false);
  let host = '127.0.0.1'
  let port = 8002
  let socket = new WebSocket(`ws://${host}:${port}/websocket`);
  /** Create and handle socket **/
  if (isOpen) {
    saveSettings2({
      "teach_mode": true,
    });
    socket.onopen = function(e) {
      //console.log('[open] Соединение установленно');
    };
    socket.onmessage = function(event) {
      let data = event.data;
      data = JSON.parse(data);
      let img = document.getElementById('stream');
      let img_b64 = `data:image/jpg;base64,${data["frame"]}`;
      if (img) {
        img.src = img_b64;
      }
    };
    socket.onclose = function(event) {
      if (event.wasClean) {
        //console.log(`[close] Соединение закрыто чисто, код=${event.code}`);
      } else {
        //console.log(`[close] Соединение прервано`);
      }
    }

  } else {
    socket && socket.close(1000, "Пользователь закрыл модалное окно");
    saveSettings2({
      "teach_mode": false,
    });
  }

  const handleModalClose = () => {
    onClose();
  };

  const goBack = () => {
    onClose();
    dispatch(toggleIsAddRecognitionModalOpen());
  };

  const handleClick = () => {
    onClose();
    setIsModalRecognitionNameOpen(true);
    //console.log(socket)
    //socket.close(1001, "Пользователь покинул страницу");
  }

  const onModalClose = () => {
    setIsModalRecognitionNameOpen(false);
  }

  return (
    <div
      className={classNames("modal", {
        "modal-recognition-start--open": isOpen,
      })}
    >
      <div
        className={classNames("modal-recognition-start__inner", {
          "modal-recognition-start__inner--day": isDay,
          "modal-recognition-start__inner--night": !isDay,
        })}
      >
        <div className="modal-recognition-start__header">
          <div className="modal-recognition-start__header-top">
            <div className="modal-recognition-start__top-container">
              <button
                className={classNames("modal-recognition-start__back", {
                  "modal-recognition-start__back--day": isDay,
                  "modal-recognition-start__back--night": !isDay,
                })}
                onClick={goBack}
              >
                <BackIco />
              </button>
              <h2 className="modal-recognition-start__title">
                Дайте роботу присмотреться
              </h2>
            </div>
            <button onClick={handleModalClose} className="modal-recognition-start__close">
              <CloseItemIco />
            </button>
          </div>
        </div>
        <div className="modal-recognition-start__body">
          <p className="modal-recognition-start__description">После нажмите или скажите готово</p>
          <div className="modal-recognition-start__container">
            <img id="stream" src="" alt="" className="modal-recognition-start__img"/>
          </div>
          {modalType ? null :
            <button
              className={classNames("modal-recognition-start-btn", {
                "modal-recognition-start-btn_day": isDay,
                "modal-recognition-start-btn_night": !isDay,
              })}
              onClick={handleClick}
            >
              Готово
            </button>
          }
        </div>
      </div>
      <Portal>
        <ModalRecognitionName
          isOpen={isModalRecognitionNameOpen}
          type={type}
          onClose={onModalClose}
         />
      </Portal>
    </div>
  );
};

export default ModalRecognitionStart;
