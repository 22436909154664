import classnames from "classnames";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setImportRecognition } from "../../store/actions";
import Portal from '../Portal';
import ModalRecognition from '../ModalRecognition/ModalRecognition';

import {ReactComponent as RecognitionIco} from "../../img/icons/menu-day/recognition.svg";
import {ReactComponent as RecognitionSignIco} from "../../img/script/sign.svg";

import "./ListRecognitions.scss";

const ListRecognitions = ({
  text,
  id,
  type,
  image,
  onClick,
  isModal = false,
  isScript = false,
  deleteRecognition,
}) => {
  const dispatch = useDispatch();
  const isDay = useSelector((state) => state.isDay);
  const [isModalRecognitionOpen, setIsModalRecognitionOpen] = useState(false);

  const handleDelete = () => {
    deleteRecognition(id);
  }
  const handleRecognitionClick = () => {
    setIsModalRecognitionOpen(!isModalRecognitionOpen);
  }
  const onModalClose = () => {
    setIsModalRecognitionOpen(!isModalRecognitionOpen);
  }
  const handleImportRecognitionData = () => {
    dispatch(setImportRecognition({id: id, text: text, type: type}))
    setIsModalRecognitionOpen(!isModalRecognitionOpen);
  }
  return (
    <>
      <li
        className={classnames("recognition__item", {
          recognition__item_day: isDay,
          recognition__item_night: !isDay,
        })}
        onClick={onClick}
      >
        <div className="recognition__name_container">
          <span className="recognition__ico">
            {type == 2 ?  <RecognitionSignIco /> : <RecognitionIco />}
          </span>
          <p className="recognition__text">{text}</p>
        </div>
        <div className="recognition__btns">
          {isModal ? (
            <button
              className={classnames("recognition__btn", {
                recognition__btn_import_day: isDay,
                recognition__btn_import_night: !isDay,
              })}
              onClick={handleImportRecognitionData}
            ></button>
          ) : (
            // в модальном окне в скриптах не показывать кнопки
            !isScript &&
            <>
              <button
                className={classnames("recognition__btn", {
                  recognition__btn_delete_day: isDay,
                  recognition__btn_delete_night: !isDay,
                })}
                onClick={handleDelete}
              ></button>
              <button
                className={classnames("recognition__btn", {
                  recognition__btn_settings_day: isDay,
                  recognition__btn_settings_night: !isDay,
                })}
                onClick={() => handleRecognitionClick()}
              ></button>
            </>
          )}
        </div>
      </li>
      <Portal>
        <ModalRecognition
          type={type}
          text={text}
          image={image}
          isOpen={isModalRecognitionOpen}
          onClose={onModalClose}
        />
      </Portal>
    </>
  );
};

export default ListRecognitions;
