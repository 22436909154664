import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { getSettings2, saveSettings2 } from "../../api";
import { toggleIsDialogPopupOpen } from "../../store/actions";

import "./DialogPopup.scss"; // Подключение стилей

const DialogPopup = () => {
  const dispatch = useDispatch();
  const isDay = useSelector((state) => state.isDay);
  const isDialogPopupOpen = useSelector((state) => state.isDialogPopupOpen);

  const [settings, setSettings] = useState({
    "id": 1,
    "name": "settings",
    "speech_enabled": true,
    "gesture_enabled": true,
    "event_enabled": true,
    "face_enabled": true,
    "priority": "1"
  });
  const onSpeechEnabledClick = () => {
    const newSettings = {...settings, speech_enabled: !settings.speech_enabled};
    setSettings(newSettings);
    saveSettings2(newSettings);
  }

  const onPriorityClick = (priority) => {
    const newSettings = {...settings, priority: priority};
    setSettings(newSettings);
    saveSettings2(newSettings);
  }

  useEffect(() => {
    getSettings2().then((res) => setSettings(res));
  }, [])

  return (
    <div
      className={classnames("dialog-popup", {
        "dialog-popup_day": isDay,
        "dialog-popup_night": !isDay,
        "dialog-popup_opened": isDialogPopupOpen,
      })}
    >
      <div
        className={classnames("dialog-popup__container", {
          "dialog-popup__container_day": isDay,
          "dialog-popup__container_night": !isDay,
        })}
      >
        <div className="dialog-popup__wrapper">
          <h2 className="dialog-popup__title">Выберите режим диалога</h2>
          <button
            type="button"
            className={classnames("dialog-popup__close", {
              "dialog-popup__close_day": isDay,
              "dialog-popup__close_night": !isDay,
            })}
            aria-label="закрыть"
            onClick={() => dispatch(toggleIsDialogPopupOpen())}
          />
        </div>

        <button
          className={classnames("dialog-popup__btn", {
            "dialog-popup__btn_day": isDay,
            "dialog-popup__btn_night": !isDay,
            // "dialog-popup__btn--disabled": settings.speech_enabled,
          })}
          onClick={onSpeechEnabledClick}
        >
          {settings.speech_enabled ? "Включен" : "Выключен"}
        </button>
        <button
          className={classnames("dialog-popup__btn", {
            "dialog-popup__btn_day": isDay,
            "dialog-popup__btn_night": !isDay,
            "dialog-popup__btn--disabled": !settings.speech_enabled,
            "dialog-popup__btn--checked": settings.priority === "2",
          })}
          disabled={!settings.speech_enabled}
          onClick={() => onPriorityClick("2")}
        >
          Команда + чат
        </button>
        <button
          className={classnames("dialog-popup__btn", {
            "dialog-popup__btn_day": isDay,
            "dialog-popup__btn_night": !isDay,
            "dialog-popup__btn--disabled": !settings.speech_enabled,
            "dialog-popup__btn--checked": settings.priority === "1",
          })}
          disabled={!settings.speech_enabled}
          onClick={() => onPriorityClick("1")}
        >
          Команда
        </button>
        <button
          className={classnames("dialog-popup__btn", {
            "dialog-popup__btn_day": isDay,
            "dialog-popup__btn_night": !isDay,
            "dialog-popup__btn--disabled": !settings.speech_enabled,
            "dialog-popup__btn--checked": settings.priority === "0",
          })}
          disabled={!settings.speech_enabled}
          onClick={() => onPriorityClick("0")}
        >
          Чат
        </button>
      </div>
    </div>
  );
};

export default DialogPopup;
