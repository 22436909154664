// import { Routes, Route } from "react-router-dom";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import "./App.scss";
import NavList from "./components/NavList/NavList";
// import RightSidebar from "./components/RightSidebar/RightSidebar";
import NavMenu from "./components/NavMenu/NavMenu";
import Profile from "./components/ProfileLink/Profile";

import LeftSidebar from "./components/LeftSidebar/LeftSidebar";
import Main from "./components/Main/Main,";

import { toggleDay } from "./store/actions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import UpdateMessage from "./components/UpdateMessage/UpdateMessage";
// import DialogPopup from "./components/DialogPopup/DialogPopup";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isDay = useSelector((state) => state.isDay);
  const update = useSelector((state) => state.update);
  const is404 = useSelector((state) => state.is404);
  const isTablet = useMediaQuery({
    query: "(max-width: 850px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 650px)",
  });
  function changeTheme() {
    localStorage.setItem('light', isDay)
    dispatch(toggleDay());
  }
  useEffect(() => {
    // debugger
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    localStorage.setItem('light', isDay)
  }, [isDay, dispatch]);
  if (is404) {
    return <h1>404</h1>
  }
  return (
    <div
      className={classnames("App", {
        App_day: isDay,
        App_night: !isDay,
      })}
    >
      {isTablet ? (
        <div className={`App__top ${isDay ? "" : "App__top--night"}`}>
          <>
            {isMobile ? null: <Profile />}
            {update.isUpdate ? <UpdateMessage /> : null}
          <NavMenu />
          </>
        </div>
      ) : (
        <LeftSidebar />
      )}
      <Main />
      {/* <button onClick={changeTheme} style={{ position: "absolute" }} /> */}
      {isTablet ? (
        <>
          {isDay ? (
            <div className="App__bottom">
              <NavList></NavList>
            </div>
          ) : (
            <div className="App__bottom--night">
              <NavList></NavList>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default App;
