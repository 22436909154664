import { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useHttp } from "../hooks/http.hook";
import LogData from "../components/LogData/LogData";
import { API_PATH } from "../api/index";
import { set404 } from "../store/actions";
import back from "../img/icons/menu-day/back-day.svg";
import backNight from "../img/icons/menu-night/back-night.svg";
import "./Logs.scss";

const Logs = () => {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState();
  const { request, loading, error } = useHttp();
  const isDay = useSelector((state) => state.isDay);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/log/`);
      const data = await response;
      setLogs(data.logs);
    };
    fetchData();
  }, []);
    if (loading) {
    return <h1 className="loading-text" style={{ textAlign: "center" }}>Идёт загрузка...</h1>;
  }
    if (error) {
    dispatch(set404(true));
  }
  return (
    <div className="logs">
      <div
        className={classnames("logs__header", {
          logs__header_day: isDay,
          logs__header_night: !isDay,
        })}
      >
        <button
          onClick={goBack}
          className={classnames("logs__back-btn", {
            "logs__back-btn_day": isDay,
            "logs__back-btn_night": !isDay,
          })}
        >
          <img
            className="logs__back-btn-img"
            alt="Back"
            src={isDay ? back : backNight}
          />
          Логи
        </button>
      </div>
      <div className="logs__content">
        <ul className="logs__list">
          {logs && logs.map(({ text, date }, index) => (
            <LogData text={text} date={date} key={index}></LogData>
          ))}
        </ul>
      </div>
    </div>

  );
};

export default Logs;
