import React, {useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import { useHttp } from "../../hooks/http.hook";
import { setRobotName } from "../../store/actions";
import { API_PATH } from "../../api/index";
import NavList from '../NavList/NavList';
import UpdateMessage from '../UpdateMessage/UpdateMessage';
import NavMenu from '../NavMenu/NavMenu';
import Profile from '../ProfileLink/Profile';
import './LeftSidebar.scss';

const LeftSidebar = () => {
  const isDay = useSelector((state) => state.isDay);
  const update = useSelector((state) => state.update);
  const dispatch = useDispatch();
  const { request } = useHttp();

  useEffect(() => {
    const fetchData = async () => {
    const response = await request(`${API_PATH}api/robot_name/`);
    const data = await response;
     dispatch(setRobotName(data.name));
    };
    fetchData();
  }, []);

  return (
    <div className={classnames("left-sidebar", {
      'left-sidebar_day': isDay,
      'left-sidebar_night': !isDay,
    })}>

      <Profile/>
      {update.isUpdate ? <UpdateMessage /> : null}
      <NavMenu/>
      <NavList/>
    </div>
  );
};

export default LeftSidebar;
