import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleIsModalOpen, setIsMove, setImportMimic } from "../../store/actions";
import classNames from "classnames";

import plus from "../../img/plus-day.svg";
import plusNight from "../../img/plus-night.svg";
import {ReactComponent as EmotionIco} from "../../img/icons/menu-day/mim.svg";

import "./RobotAddSmt.scss";

const RobotAddSmt = ({ word, pharsa, handlePhrasaChange, mimic, mimicName, handleMimicChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMimic, setIsMimic] = useState(mimic ? true : false);
  const [addMimic, setAddMimic] = useState(mimic);
  const [formValue, setFormValue] = useState(pharsa);
  const isDay = useSelector((state) => state.isDay);
  const importMimic = useSelector((state) => state.importMimic);
  const mimics = useSelector((state) => state.mimics);
  //получать мимику при первом рендеринге
  const [importMimicName, setImportMimicName] = useState(mimic ? mimics.filter((item) => item.id == mimic).name : null);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const handleClick = (e) => {
    e.preventDefault();
    if (!isOpen) {
      setIsOpen(!isOpen);
    }
    if (e.target.tagName === "H3" && isOpen) {
      setIsOpen(!isOpen);
    }
    if (e.target.tagName !== "BUTTON") {
      return;
    }
    setIsOpen(!isOpen);
  };
  const handleMimicOpen = (e) => {
    //e.preventDefault();
    dispatch(setIsMove(false));
    dispatch(toggleIsModalOpen());
    if(!isMimic) {
      setIsMimic(false);
    }
    if(isMimic) {
      setIsMimic(false);
    }
    setIsMimic(true);
    handleMimicChange(addMimic);
  };
  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = inputRef.current.value;
      setFormValue(inputValue);
      handlePhrasaChange(inputValue);

      setIsOpen(false);
      inputRef.current.value = "";
    }
  };
  useEffect(() => {
    setImportMimicName(importMimic ? importMimic.text : mimics.filter((item) => item.id == mimic).name);
    setAddMimic(importMimic ? importMimic.id : mimic);
  }, [addMimic, importMimicName, mimic, importMimic, mimics, handleMimicChange]);
  return (
    <button
      onClick={
        word === "фразу" ? (e) => handleClick(e) : (e) => handleMimicOpen(e)
      }
      className={classNames("robotaddsmt", {
        "robotaddsmt--night": !isDay,
        "robotaddsmt--day": isDay,
        "robotaddsmt--active": isOpen || isMimic,
      })}
    >
      <img
        className={isOpen || isMimic ? "robotaddsmt__img--active" : ""}
        src={isDay ? plus : plusNight}
        alt="Plus"
      />
      <h3
        className={classNames("robotaddsmt__text", {
          "robotaddsmt__text--night": !isDay,
          "robotaddsmt__text--day": isDay,
          "robotaddsmt__text--hidden": isMimic,
        })}
      >
        {formValue ? formValue : `Добавить ${word}`}
      </h3>
      {isOpen ? (
        <input
          placeholder="Введите текст для робота"
          className={classNames("robotaddsmt__input", {
            "robotaddsmt__input--night": !isDay,
            "robotaddsmt__input--day": isDay,
          })}
          onKeyDown={(e) => handleSubmit(e)}
          type="text"
          ref={inputRef}
        />
      ) :
      null}
      {isMimic ? (
          <div
            className="robotaddsmt__mimic-container"
          >
              <EmotionIco />
              <h3
                className={classNames("robotaddsmt__text", {
                  "robotaddsmt__text--night": !isDay,
                  "robotaddsmt__text--day": isDay,
                })}
              >
                {mimicName ? mimicName : (
                  <>
                    { addMimic ? importMimicName : null }
                  </>
                )
                }
              </h3>
          </div>
          ) :  null}
    </button>
  );
};

export default RobotAddSmt;
