import React, {useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHttp } from "../../hooks/http.hook";
import classnames from "classnames";
import HomeList from "../HomeList/HomeList";
import { runMovePost,  runMimicPost, API_PATH } from "../../api/index";
import { setMoves, setMimics } from "../../store/actions";
import "./RightSidebar.scss";

const RightSidebar = () => {
  const isDay = useSelector((state) => state.isDay);
    //получение списка всех мимик и движений
    const dispatch = useDispatch();
    const { request, loading, error, clearError } = useHttp();

  useEffect(() => {
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/move/`);
      const data = await response;
      //console.log(data);
      dispatch(setMoves(data));
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/mimic/`);
      const data = await response;
      // console.log(data);
      dispatch(setMimics(data));
    };
    fetchData();
  }, []);
  const moves = useSelector((state) => state.moves);
  const mimics = useSelector((state) => state.mimics);

  const onPlayMoveClick = (moves) => {
    runMovePost(moves.id);
  }

  const onPlayMimicClick = (mimics) => {
    runMimicPost(mimics.id);
  }

  return (
    <div
      className={classnames("right-sidebar", {
        "right-sidebar_day": isDay,
        "right-sidebar_night": !isDay,
      })}
    >
      <HomeList title="Движения" items={moves} onPlayClick={onPlayMoveClick} loading={loading}/>
      <HomeList title="Мимика" items={mimics} onPlayClick={onPlayMimicClick} />
    </div>
  );
};

export default RightSidebar;
