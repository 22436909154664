import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { setUpdate } from "../../store/actions";
import {ReactComponent as UpdateIco} from "../../img/icons/menu-day/update.svg";
import { getUpdate } from "../../api";

import './UpdateMessage.scss';

const UpdateMessage = () => {
  const isDay = useSelector((state) => state.isDay);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setUpdate({
      isUpdate: false,
      updateVersion: "версия 3.33.9",
   }));
  }
  const handleUpdate = async () => {
    await getUpdate();
    dispatch(setUpdate({
      isUpdate: false,
      updateVersion: "версия 3.33.9",
   }));
  }


  return (
    <div
    className={classNames("updatemessage", {
      updatemessage_day: isDay,
      updatemessage_night: !isDay,
    })}
    >
      <div className="updatemessage__container">
        <UpdateIco />
        <h2
          className={classNames("updatemessage__title", {
            updatemessage__title_day: isDay,
            updatemessage__title_night: !isDay,
          })}
        >
          Доступно обновление
        </h2>
      </div>
      <div className="updatemessage__buttons-container">
        <button
          className={classNames("updatemessage__button_colored", {
            updatemessage__button_colored_day: isDay,
            updatemessage__button_colored_night: !isDay,
          })}
          onClick={handleUpdate}
        >
          Установить сейчас
        </button>
        <button
          className={classNames("updatemessage__button", {
            updatemessage__button_day: isDay,
            updatemessage__button_night: !isDay,
          })}
          onClick={handleClick}
        >
          Напомнить позже
        </button>
      </div>
    </div>
  );
};

export default UpdateMessage;