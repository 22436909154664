import React, {useEffect } from "react";
import DialogPopup  from "../components/DialogPopup/DialogPopup";
import { useSelector, useDispatch } from "react-redux";
import { useHttp } from "../hooks/http.hook";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import Robot from "../components/Robot/Robot";
import { setMoves, setMimics, setSettings, setUpdate, set404 } from "../store/actions";
import { API_PATH } from "../api/index";
import RightSidebar from "../components/RightSidebar/RightSidebar";
import "./Home.scss";

const Home = () => {
  //получение списка всех мимик и движений
  const dispatch = useDispatch();
  const { request, loading, error, clearError } = useHttp();
  /*useEffect(() => {
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/move/`);
      const data = await response;
      // console.log(data);
      dispatch(setMoves(data));
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await request(`${API_PATH}api/mimic/`);
      const data = await response;
      // console.log(data);
      dispatch(setMimics(data));
    };
    fetchData();
  }, []);*/
  useEffect(() => {
    const fetchData = async () => {
    const response = await request(`${API_PATH}api/endstops/1/`);
    const data = await response;
    dispatch(setSettings(data));
    }
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
    const response = await request(`${API_PATH}api/update_check/`);
    const data = await response;
    dispatch(setUpdate({
        isUpdate: data,
        updateVersion: "версия 3.33.9",
     }));
    };
    fetchData();
  }, []);
  const isTablet = useMediaQuery({
    query: "(max-width: 850px)",
  });
  const isPc = useMediaQuery({
    query: "(max-width: 1285px)",
  });
  const isDay = useSelector((state) => state.isDay);
  if (loading) {
    return <h1 className="loading-text" style={{ textAlign: "center" }}>Идёт загрузка...</h1>;
  }
  if (error) {
    dispatch(set404(true));
  }
  return (
    <div className="home__wrapper">
      <DialogPopup />
      <div
        className={classnames("home", {
          home_day: isDay,
          home_night: !isDay,
        })}
      >
        <Robot />
        <>{isPc ? null : <RightSidebar />}</>
      </div>
    </div>
  );
};

export default Home;
