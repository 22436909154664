export const codeGenerator = (function (start = 0) {
  return () => ++start;
}());

export const getTheme = () => {
  const isDayTheme = `${window?.localStorage?.getItem('light')}`
  if (isDayTheme == true) return true;
  if (isDayTheme == false) return false;
  const userMedia = window.matchMedia('(prefers-color-scheme: light)');
  if (userMedia.matches) return true;
  return false;
}

//для склонения слова раз\раза
export const numberTimes = (number) => {
  if (number == null) {
    return "раз";
  }

  number = number.toString();

  if (2 <= number[number.length-1] && number[number.length-1] <= 4) {
    return "раза";
  } else {
    return "раз";
  }
}

//для склонения слова минут
export const minutes = (minute) => {
  if (minute == null) {
    return "минут";
  }

  minute = minute.toString();

  if (minute.match(/11$/)) {
    return "минут";
  }
  const end = minute[minute.length-1];
  //если окончание от 2-4 выводим минуты
  if (2 <= end && end <= 4) {
    return "минуты";
    //если окончание 1 выводим минута
  } else if (end == 1) {
    return "минута";
    // если окончание 0 или больше 4
  } else {
    return "минут";
  }
}
//обертка для функции чтобы запускать функцию func не раньше чем ms-милисекунд после предыдущего запуска
export const debounce = (func, ms) => {
  let timeout;
  return function() {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, arguments), ms);
  };
}

