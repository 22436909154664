import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import back from "../img/icons/menu-day/back-day.svg";
import backNight from "../img/icons/menu-night/back-night.svg";

import "./Access.scss";
import { getSettings2, saveSettings2 } from "../api";



const Access = () => {
  const isDay = useSelector((state) => state.isDay);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [settings, setSettings] = useState({
    "id": 1,
    "name": "settings",
    "speech_enabled": true,
    "gesture_enabled": true,
    "event_enabled": true,
    "face_enabled": true,
    "priority": "1"
  });
  const saveSettings = () => {
    saveSettings2(settings);
  }
  const onSpeechEnabledChange = (e) => {
    setSettings({...settings, speech_enabled: e.target.checked})
  }

  useEffect(() => {
    getSettings2().then((res) => setSettings(res));
  }, [])

  return (
    <div className="access__wrapper">
      <div
        className={classnames("access", {
          access_day: isDay,
          access_night: !isDay,
        })}
      >

        <div className="access__content">
          <div
            className={classnames("access__header", {
              access__header_day: isDay,
              access__header_night: !isDay,
            })}
          >
            <button
              onClick={goBack}
              className={classnames("access__back-btn", {
                "access__back-btn_day": isDay,
                "access__back-btn_night": !isDay,
              })}
            >
              <img
                className="access__back-btn-img"
                alt="Back"
                src={isDay ? back : backNight}
              />
              Доступы
            </button>
          </div>
          <div className={classnames("access__content_items", {
              access__content_items_day: isDay,
              access__content_items_night: !isDay,
            })}
          >
            comming soon...
            <label>
              <input
                type="checkbox"
                checked={settings.speech_enabled}
                onChange={onSpeechEnabledChange}
              />
              Диалог
            </label>
            <button onClick={saveSettings}>Сохранить</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Access;
